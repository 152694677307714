import React, { Component } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import ContentFormatter from '../components/project-content-formatter'
import ContentAdditionalFormatter from '../components/project-content-additional-formatter'
import parse, { domToReact } from 'html-react-parser'
import Carousel from '../components/carousel'
import Accordion from '../components/accordion'
import Layout from "../components/layout"
import SEO from "../components/seo"


class Page extends Component {
  render() {
    const StaticPage = this.props.data.wpProject
    let seo = StaticPage.seo
    let title = StaticPage.title
    let content = null
    let contentAdditional = null
    let location = ''
    let panelArea = ''
    let texture = ''
    let color = ''
    let architect = ''
    let contractor = ''
    let owner = ''
    let installation = ''
    let installer = ''
    let fasteners = ''
    if(StaticPage){
      title = StaticPage.title
      location = StaticPage.location
      panelArea = StaticPage.panelArea
      texture = StaticPage.texture
      color = StaticPage.color
      architect = StaticPage.architect
      contractor = StaticPage.contractor
      owner = StaticPage.owner
      installation = StaticPage.installation
      installer = StaticPage.installer
      fasteners = StaticPage.fasteners
      //Fix self-closing spacers, ampersand tags and returns
      StaticPage.content = StaticPage.content
        .replace(/<div (class="kt-block-spacer.*)\/>/gi,'<div $1></div>')
        .replace(/&amp;nbsp;/gi, '&nbsp;')
        .replace(/\t/gi, '')
        .replace(/\n/gi, '')
      contentAdditional = []
      content = []
      let tempContent = StaticPage.content

      let components = []
      if(!parse(tempContent).length) {
        components.push(parse(tempContent))
      }else{
        components = parse(tempContent)
      }
      content = components.map(function(element, i){
        let className = ''
        if(element.props){
          if(element.props.className){
            className = element.props.className
          }
        }
        if(element.type === 'div' && className.includes('gallery')){
          return (null)
        }else if(element.type === 'figure' && className.includes('gallery')){
          return (null)
        }else if(element.type === 'style'){
          return(null)
        }else{
          if(element){
            if(element.type){
              if(!(element.type === 'p' && !element.props.children)){
                contentAdditional.push(element)
              }
            }
          }
          return(
            null
          )
        }
      })
      let meta = StaticPage.project_meta_fields
      if(contentAdditional.length > 0){
        contentAdditional = <div className="project-moreinfo content">{contentAdditional}</div>
      }
    }
    return (
    <Layout location={this.props.location} className='projects'>
      <SEO title={title} />
      <div className="columns content">
        <ContentFormatter content={StaticPage.content} />
        <div className="column">
          {/* Page title */}
          <section className="section">
          <h1 className="title is-spaced" dangerouslySetInnerHTML={{ __html: title }} />
          </section>
          <ContentAdditionalFormatter content={StaticPage.content} />
          <section className="section">
            <table className="project-specs">
              <tbody>
              {location && <tr><td>Location </td><td dangerouslySetInnerHTML={{ __html: location }} /></tr>}
              {panelArea && <tr><td>Panel Area </td><td dangerouslySetInnerHTML={{ __html: panelArea }} /></tr>}
              {texture && <tr><td>Texture </td><td dangerouslySetInnerHTML={{ __html: texture }} /></tr>}
              {color && <tr><td>Color </td><td dangerouslySetInnerHTML={{ __html: color }} /></tr>}
              {architect && <tr><td>Architect </td><td dangerouslySetInnerHTML={{ __html: architect }} /></tr>}
              {contractor && <tr><td>Contractor </td><td dangerouslySetInnerHTML={{ __html: contractor }} /></tr>}
              {owner && <tr><td>Owner </td><td dangerouslySetInnerHTML={{ __html: owner }} /></tr>}
              {installation && <tr><td>Installation </td><td dangerouslySetInnerHTML={{ __html: installation }} /></tr>}
              {installer && <tr><td>Installer </td><td dangerouslySetInnerHTML={{ __html: installer }} /></tr>}
              {fasteners && <tr><td>Fasteners </td><td dangerouslySetInnerHTML={{ __html: fasteners }} /></tr>}
              </tbody>
            </table>
          </section>
          <section className="section">
            <div className="project-links">
              <Link to="/projects/" title="Featured Projects">Featured</Link> | <Link to="/projects-a-z/" title="Projects from A to Z">A-Z</Link>
            </div>
          </section>
        </div>
      </div>
    </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wpProject(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      architect
      color
      contractor
      fasteners
      features
      installation
      location
      owner
      panelArea
      shortName
      substructure
      texture
      installer
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
