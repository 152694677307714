import React from 'react'
import Parser, { domToReact } from 'html-react-parser'
import Accordion from './accordion'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from 'simple-react-lightbox'

const options = {
  replace: (domNode, children) => {
    console.log(domNode)
    if(domNode.attribs?.class?.includes('wp-block-gallery') || domNode.attribs?.class?.includes('gallery')){
      domNode.children = []
      domNode = null
      return (null)
    }else if(domNode.type === 'style'){
      domNode.children = []
      domNode = null
      return (null)
    }else if (domNode.attribs?.class?.includes('lightbox')) {
      return(<SimpleReactLightbox><SRLWrapper options={{
        thumbnails: {
          showThumbnails: true
        }
      }}>{domToReact([domNode])}</SRLWrapper></SimpleReactLightbox>)
    }else {
      return domNode
    }
  }
}

class ProjectContentAdditionalFormatter extends React.Component {
  constructor(props) {
    super(props)
    let firstItem = true
    let content = []
    if(Array.isArray(Parser(this.props.content, options))){
      content = Parser(this.props.content, options)
      if(Array.isArray(content)){
        content.map((item,i)=>{
          if(typeof item === 'string'){
            return null
          }else{
            if(item.type === 'style'){
              return(null)
            }else if(item.props?.className?.includes('gallery')){
              return(null)
            }else{
              return (item)
            }
          }
        })
      }
    }
    this.state = {
      hasMounted: false,
      content: content
    }
  }

  onComponentDidMount(){
    console.log('hasMounted')
    this.setState({
      hasMounted: true
    })
  }

  render() {
    return(<ClientOnly>{this.state.content}</ClientOnly>)
  }
}

export default ProjectContentAdditionalFormatter

function ClientOnly({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return (
    <section className="section" {...delegated}>
      {children}
    </section>
  );
}
